<template>
  <div class="supplier-orders">
    <div class="page-header">
      <h2>供应商订单</h2>
    </div>
    <div class="page-content">
      <!-- 筛选条件 -->
      <el-form :inline="true" class="filter-form" @submit.native.prevent>
        <el-form-item label="订单编号">
          <el-input v-model="queryParams.order_number" placeholder="请输入订单编号" clearable @clear="handleSearch" @keyup.enter.native="handleSearch"></el-input>
        </el-form-item>
        <el-form-item label="供应商名称">
          <el-input v-model="queryParams.supplier_name" placeholder="请输入供应商名称" clearable @clear="handleSearch" @keyup.enter.native="handleSearch"></el-input>
        </el-form-item>
        <el-form-item label="品牌">
          <el-select v-model="queryParams.brand" placeholder="请选择品牌" clearable filterable @change="handleSearch">
            <el-option v-for="brand in brandOptions" :key="brand" :label="brand" :value="brand">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="客户名称">
          <el-select v-model="queryParams.customer_name" placeholder="请选择客户" clearable filterable @change="handleSearch">
            <el-option v-for="customer in customerOptions" :key="customer" :label="customer" :value="customer">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="结算时间">
          <el-date-picker v-model="dateRange" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" @change="handleDateRangeChange">
          </el-date-picker>
        </el-form-item>
      </el-form>

      <!-- 数据表格 -->
      <el-card class="table-card">
        <div slot="header" class="card-header">
          <span>订单列表</span>
          <el-button icon="el-icon-refresh" size="small" title="刷新列表并重置筛选条件" @click="resetQuery"></el-button>
        </div>
        <el-table v-loading="loading" :data="orderList" border style="width: 100%" :header-cell-style="{ background: '#f5f7fa', color: '#606266' }">
          <el-table-column prop="order_number" label="订单编号" width="120" show-overflow-tooltip></el-table-column>
          <el-table-column prop="brand" label="品牌" width="120" show-overflow-tooltip></el-table-column>
          <el-table-column prop="supplier_product" label="供应商产品" width="150" show-overflow-tooltip></el-table-column>
          <el-table-column prop="supplier_name" label="供应商名称" width="150" show-overflow-tooltip></el-table-column>
          <el-table-column prop="policy_number" label="政策编号" width="120" show-overflow-tooltip></el-table-column>
          <el-table-column prop="policy_short_name" label="政策简称" width="120" show-overflow-tooltip></el-table-column>
          <el-table-column prop="customer_name" label="客户名称" width="150" show-overflow-tooltip></el-table-column>
          <el-table-column prop="settlement_start_time" label="结算开始" width="120" show-overflow-tooltip></el-table-column>
          <el-table-column prop="settlement_end_time" label="结算结束" width="120" show-overflow-tooltip></el-table-column>
          <el-table-column prop="settlement_net_amount" label="结算净额" width="120" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ formatAmount(scope.row.settlement_net_amount) }}
            </template>
          </el-table-column>
          <el-table-column prop="payable_amount" label="应付金额" width="120" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ formatAmount(scope.row.payable_amount) }}
            </template>
          </el-table-column>
          <el-table-column prop="total_paid_amount" label="已付款金额" width="120" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ formatAmount(scope.row.total_paid_amount) }}
            </template>
          </el-table-column>
          <el-table-column prop="unpaid_amount" label="未付款金额" width="120" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ formatAmount(scope.row.unpaid_amount) }}
            </template>
          </el-table-column>
          <el-table-column prop="order_type" label="订单类型" width="120" show-overflow-tooltip></el-table-column>
          <el-table-column prop="payment_status" label="付款状况" width="120" show-overflow-tooltip></el-table-column>
          <el-table-column prop="contract_status" label="合同状况" width="120" show-overflow-tooltip></el-table-column>
          <el-table-column prop="cooperation_content" label="合作内容" width="150" show-overflow-tooltip></el-table-column>
          <el-table-column fixed="right" label="操作" width="120">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="openOrderDetail(scope.row)">查看详情</el-button>
            </template>
          </el-table-column>
        </el-table>

        <!-- 分页 -->
        <div class="pagination-container">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryParams.page" :page-sizes="[10, 20, 30]" :page-size="queryParams.page_size" layout="total, sizes, prev, pager, next, jumper" :total="total">
          </el-pagination>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import {
  getSupplierOrders,
  getSupplierOrderBrands,
  getSupplierOrderCustomers,
} from "@/api/index";

export default {
  name: "SupplierOrders",
  data () {
    return {
      // 查询参数
      queryParams: {
        order_number: "",
        supplier_name: "",
        brand: "",
        customer_name: "",
        start_date: "",
        end_date: "",
        page: 1,
        page_size: 10,
      },
      // 日期范围
      dateRange: [],
      // 品牌选项
      brandOptions: [],
      // 客户选项
      customerOptions: [],
      // 订单列表
      orderList: [],
      // 总记录数
      total: 0,
      // 加载状态
      loading: false,
    };
  },
  created () {
    this.getOptions();
    this.getList();
  },
  methods: {
    // 获取下拉选项
    async getOptions () {
      try {
        // 获取品牌列表
        const brandRes = await getSupplierOrderBrands();
        this.brandOptions = brandRes.data || [];

        // 获取客户列表
        const customerRes = await getSupplierOrderCustomers();
        this.customerOptions = customerRes.data || [];
      } catch (error) {
        console.error("获取选项失败:", error);
        this.$message.error("获取选项数据失败");
      }
    },
    // 获取订单列表
    async getList () {
      this.loading = true;
      try {
        // 处理日期范围
        if (this.dateRange && this.dateRange.length === 2) {
          this.queryParams.start_date = this.dateRange[0];
          this.queryParams.end_date = this.dateRange[1];
        } else {
          this.queryParams.start_date = "";
          this.queryParams.end_date = "";
        }

        const res = await getSupplierOrders(this.queryParams);
        this.orderList = res.data.list || [];
        this.total = res.data.pagination.total || 0;
      } catch (error) {
        console.error("获取订单列表失败:", error);
        this.$message.error("获取订单列表失败");
      } finally {
        this.loading = false;
      }
    },
    // 处理查询
    handleSearch () {
      this.queryParams.page = 1;
      this.getList();
    },
    // 重置查询
    resetQuery () {
      this.queryParams = {
        order_number: "",
        supplier_name: "",
        brand: "",
        customer_name: "",
        start_date: "",
        end_date: "",
        page: 1,
        page_size: 10,
      };
      this.dateRange = [];
      this.getList();
    },
    // 处理每页条数变化
    handleSizeChange (size) {
      this.queryParams.page_size = size;
      this.getList();
    },
    // 处理页码变化
    handleCurrentChange (page) {
      this.queryParams.page = page;
      this.getList();
    },
    // 格式化金额
    formatAmount (amount) {
      if (amount === null || amount === undefined) return "-";
      return parseFloat(amount).toLocaleString("zh-CN", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
    // 格式化百分比
    formatPercentage (value) {
      if (value === null || value === undefined) return "-";
      return (parseFloat(value) * 100).toFixed(2) + "%";
    },
    // 格式化数字（用于粉丝量、播放量等）
    formatNumber (value) {
      if (value === null || value === undefined) return "-";

      // 将数字转换为字符串
      const num = parseFloat(value);

      // 如果数字大于等于10000，则以万为单位显示
      if (num >= 10000) {
        return (num / 10000).toFixed(1) + "w";
      }

      // 否则直接返回数字
      return num.toLocaleString("zh-CN");
    },
    // 格式化日期
    formatDate (dateString) {
      if (!dateString) return "-";
      return dateString.substring(0, 10); // 只显示日期部分
    },
    // 处理日期范围变化
    handleDateRangeChange () {
      this.handleSearch();
    },
    // 打开订单详情页
    openOrderDetail (row) {
      // 使用 window.open 在新窗口中打开详情页
      const routeUrl = this.$router.resolve({
        path: '/suppliers/order-detail',
        query: { order_id: row.order_id }
      });
      window.open(routeUrl.href, '_blank');
    },
  },
};
</script>

<style scoped>
.supplier-orders {
  padding: 20px;
}

.page-header {
  margin-bottom: 20px;
}

.filter-form {
  margin-bottom: 20px;
  background-color: #f5f7fa;
  padding: 20px;
  border-radius: 4px;
}

.table-card {
  margin-bottom: 20px;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination-container {
  margin-top: 20px;
  text-align: right;
}
</style>